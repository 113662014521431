import { Link } from "@inertiajs/react"
import ApplicationLogo from "@/components/ApplicationLogo"
import { Button } from "@mui/material"
import { CancelOutlined, CheckSharp } from "@mui/icons-material"

export default function SignUp({ handleClose }: { handleClose: () => void }) {
    return (
        <div
            className={`
                absolute bottom-0 left-0 right-0 top-0 w-screen overflow-hidden
                bg-bonza-dark
            `}
        >
            <nav>
                <div
                    className={`
                        mx-auto max-w-7xl px-4

                        lg:px-8

                        sm:px-6
                    `}
                >
                    <div className="flex h-16 justify-between">
                        <div className="flex">
                            <div className="flex shrink-0 items-center">
                                <Link href="/">
                                    <ApplicationLogo
                                        className={`
                                            float-left h-9 w-auto fill-current
                                        `}
                                    />
                                    <p
                                        className={`
                                            float-left -mt-1 pl-2 pt-1 text-3xl
                                            font-bold lowercase text-gray-800

                                            dark:text-gray-200
                                        `}
                                    >
                                        Bonza
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div
                            className={`
                                mt-2 hidden

                                sm:ms-6 sm:flex sm:items-center
                            `}
                        >
                            <Button onClick={() => handleClose()}>
                                <CancelOutlined className="text-white" />
                            </Button>
                        </div>
                    </div>
                </div>
            </nav>

            <div
                className="mx-auto flex h-full max-w-7xl flex-col"
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div>
                    <h1 className="text-3xl text-white">Choose your plan</h1>
                </div>

                <div
                    className={`
                        grid h-2/3 grid-cols-3 gap-6 text-white

                        md:mx-6 md:px-6
                    `}
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        className={`
                            relative h-4/6 rounded-3xl border border-gray-600
                            p-10
                        `}
                    >
                        <h2 className="-mt-1 pb-3 text-xl">
                            Free 2 week trial
                        </h2>
                        <p className="pb-3 text-sm">
                            Now is the time for all good men to come to the aid
                            of the party.
                        </p>
                        <ul className="text-sm">
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                First
                            </li>
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                Second
                            </li>
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                Third
                            </li>
                        </ul>
                        <div className="absolute bottom-0 m-3 pb-3">
                            <div
                                className={`
                                    mb-4 w-60 border-t border-gray-600 pt-3
                                `}
                            >
                                <p>
                                    <span className="text-3xl font-semibold">
                                        £0
                                    </span>
                                    <span className="text-sm">/month</span>
                                </p>
                                <p className="text-sm">try bonza for free</p>
                                <button
                                    className={`
                                        mt-3 w-full rounded-3xl bg-bonza-primary
                                        p-2 text-bonza-dark-semi
                                    `}
                                >
                                    Sign up
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`
                            relative h-4/6 rounded-3xl border
                            border-bonza-primary bg-bonza-primary p-10
                        `}
                    >
                        <h2 className="-mt-1 pb-3 text-xl">Individual plan</h2>
                        <p className="pb-3 text-sm">
                            Now is the time for all good men to come to the aid
                            of the party.
                        </p>
                        <ul className="text-sm">
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                First
                            </li>
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                Second
                            </li>
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                Third
                            </li>
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                Fourth
                            </li>
                        </ul>
                        <div className="absolute bottom-0 m-3 pb-3">
                            <div
                                className={`
                                    mb-4 w-60 border-t border-bonza-primary
                                `}
                            >
                                <p>
                                    <span className="text-3xl font-semibold">
                                        £25
                                    </span>
                                    <span className="text-sm">/month</span>
                                </p>
                                <p className="text-sm">
                                    per person, cancel anytime
                                </p>
                                <button
                                    className={`
                                        mt-3 w-full rounded-3xl bg-bonza-primary
                                        p-2 text-bonza-dark-semi
                                    `}
                                >
                                    Sign up
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`
                            relative h-4/6 rounded-3xl border
                            border-bonza-dark-semi bg-bonza-dark-semi p-10
                        `}
                    >
                        <h2 className="-mt-1 pb-3 text-xl">Group plan</h2>
                        <p className="pb-3 text-sm">
                            Now is the time for all good men to come to the aid
                            of the party.
                        </p>
                        <ul className="text-sm">
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                First
                            </li>
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                Second
                            </li>
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                Third
                            </li>
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                Fourth
                            </li>
                            <li>
                                <CheckSharp className="text-xs text-bonza-primary" />{" "}
                                Fifth
                            </li>
                        </ul>
                        <div className="absolute bottom-0 m-3 pb-3">
                            <div
                                className={`
                                    mb-4 w-60 border-t border-gray-600 pt-3
                                `}
                            >
                                <p>
                                    <span className="text-3xl font-semibold">
                                        £19
                                    </span>
                                    <span className="text-sm">/month</span>
                                </p>
                                <p className="text-sm">
                                    per person, cancel anytime
                                </p>
                                <button
                                    className={`
                                        mt-3 w-full rounded-3xl bg-bonza-primary
                                        p-2 text-bonza-dark-semi
                                    `}
                                >
                                    Sign up
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="absolute right-5 top-5">
                    <a onClick={() => handleClose()}>Close</a>
                </div>
            </div>
        </div>
    )
}
